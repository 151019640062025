import pptxgen from "pptxgenjs";
import { BASE_TABLE_OPTS, BASE_TEXT_OPTS_L, BASE_TEXT_OPTS_R, FOOTER_TEXT_OPTS, IMAGE_PATHS, TESTMODE } from "./enums.mjs";
import {
	COLORS_ACCENT,
	CHART_DATA,
	COLORS_CHART,
	COLORS_RYGU,
	COLORS_SPECTRUM,
	COLORS_VIVID,
	LETTERS,
	MONS,
	arrDataLineStat,
	dataChartBar3Series,
	dataChartBar8Series,
	dataChartPieLocs,
	dataChartPieStat,
} from "./enums_charts.mjs";
import './App.css';

const data = [
	{
		name: "Əməliyyat sistemləri",
		labels: ["Windows", "IOS", "Android", "Digər"],
		values: [9.3, 11.2, 67, 12.4],
	},
];

const saveOS = () => {
  let pptx = new pptxgen();

  let slide = pptx.addSlide({ sectionTitle: "Charts" });
	slide.addText("Əməliyyat sistemləri", { x: 0, y: 0.3, w: 3, color: "111111", fontSize: 18 });

	// BTM-MIDDLE
	slide.addChart(pptx.charts.PIE, data, {
		x: 0,
		y: 0.5,
		w: 4.0,
		h: 3.2,
		chartArea: { fill: { color: "F1F1F1" } },
		dataBorder: { pt: "1", color: "F1F1F1" },
		chartColors: COLORS_SPECTRUM,
		dataLabelColor: "F1F1F1",
		showPercent: true,
		showLegend: true,
		legendPos: "b",
	});

  // 4. Save the Presentation
  pptx.writeFile({ fileName: "Sample Presentation.pptx" });
}

function App() {
  return (
    <div className="App">
      <div className="slides">
        <div>Əməliyyat sistemləri</div>
        <div onClick={saveOS}>Yüklə</div>
      </div>
    </div>
  );
}

export default App;
